import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Address from "../components/address"
import { Container, Row, Col, Figure } from "react-bootstrap";

const AboutPage = () => (
  <Layout>
    <SEO title="Portfolio" keywords={[`SugarCRM`, `SuiteCRM`, `WordPress`, `Customized Websites`]}/>
    
      <Container>
      <br></br>
        <p className="lead">
        Our Portfolio
        </p>
      <br/>
      <Row>
        <Col md={4}>
        <Figure>
          <Figure.Caption>
            SugarCRM / SuiteCRM Installation and Customization
          </Figure.Caption>
          
          <Figure.Image
            width={240}
            height={200}
            alt="SuiteCRM"
            src="/img/suitecrm.png"
          />
        </Figure>
        </Col>
        <Col md={4}>
        <Figure>
          <Figure.Caption>
            CasaPages Classified Website
          </Figure.Caption>
          <Figure.Image
            width={240}
            height={200}
            alt="angular"
            src="/img/casapages.png"
          />
        </Figure>
        </Col>
        <Col md={4}>
        <Figure>
          <Figure.Caption>
            Pinca Classified Website
            <br/><br/>
           
          </Figure.Caption>
          <Figure.Image
            width={240}
            height={200}
            alt="Pinca"
            src="/img/pinca.png"
          />
        </Figure>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Figure>
            <Figure.Caption>
              OSCAR EMR / Clinic Management System Customization
              <br/><br/>
            
            </Figure.Caption>
            <Figure.Image
              width={240}
              height={200}
              alt="OSCAR EMR"
              src="/img/oscar_emr.png"
            />
          </Figure>
        </Col>
        <Col md={4}>
          <Figure>
            <Figure.Caption>
              OSCAR EMR SelfBook Customization
              <br/><br/>
            
            </Figure.Caption>
            <Figure.Image
              width={240}
              height={200}
              alt="OSCAR EMR"
              src="/img/selfbook.png"
            />
          </Figure>
        </Col>
      </Row>

    </Container>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <Address/>
  </Layout>
)

export default AboutPage